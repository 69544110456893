.home-page-wrapper {
  .main-slider.slick-slider {
    margin-bottom: 85px;
    .slide {
      .image-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          height: 500px;
          width: auto;
          min-width: fit-content;
        }
      }
    }
    .slick-dots {
      bottom: -30px;
      li {
        @include size(8px);
        button {
          @include size(8px);
          border: 1px solid #d0d0d0;
          background-color: #eaeaea;
        }
        &.slick-active {
          button {
            border-color: #BF6011;
            background-color: $orange;
          }
        }
      }
    }
  }
  section.about {
    .title-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 10px;
      h2 {
        font-size: 30px;
        color: $blue;
        text-transform: uppercase;
        margin-top: -10px;
        line-height: 44px;
        b {
          display: block;
          color: $orange;
          font-size: 46px;
        }
      }
      p {
        font-size: 21px;
      }
    }
  }
  section.select-product {
    .products-wrapper {
      border: 3px solid $blue;
      margin: 0 -90px 100px;
      h2 {
        font-size: 40px;
        color: $blue;
        text-align: center;
        text-transform: uppercase;
        display: table;
        margin: auto;
        background-color: #fff;
        position: relative;
        top: -28px;
        padding: 0 40px;
      }
      .product-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 80px;
        .product {
          position: relative;
          text-align: center;
          h4 {
            opacity: 0;
            transition: all ease .3s;
            transform: translateY(-10px);
            position: absolute;
            font-size: 20px;
            font-weight: bold;;
            margin: 0;
            left: -50px;
            right: -50px;
            bottom: -80px;
            text-align: center;
            text-transform: uppercase;
            color: $gray;
          }
          &:after {
            opacity: 0;
            transition: all ease .3s;
            transform: scaleX(.3);
            content: " ";
            position: absolute;
            width: 120px;
            left: 50%;
            right: auto;
            margin-left: -60px;
            bottom: -37px;
            height: 13px;
            background-color: $orange-light;
          }
          &:hover {
            h4 {
              opacity: 1;
              transition: all ease .3s;
              transform: translateY(0px);
            }
            &:after {
              opacity: 1;
              transition: all ease .3s;
              transform: scaleX(1);
              width: 120px;
            }
          }
        }
      }
    }
  }
  section.videos {
    background: url("../img/videos-bg.jpg") no-repeat center center;
    padding: 45px 0;
    h2 {
      display: table;
      margin: 0 auto 35px;
      position: relative;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      img {
        position: absolute;
        right: -100px;
        top: -10px;
      }
    }
    .video-slider-wrapper {
      margin: 0 -12px;
      padding: 10px 0 45px;
      position: relative;
      .video-slider {
        .video-box {
          margin: 0 12px;
          figure {
            margin: 0;
            position: relative;
            img {
              opacity: .8;
            }
            i {
              color: #fff;
              opacity: .7;
              font-size: 40px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 50%;
              margin-top: -20px;
              transition: all ease .3s;
            }
          }
          h4 {
            color: #fff;
            font-size: 17px;
            font-weight: bold;
            margin: 10px 0;
            text-transform: uppercase;
          }
          &:hover {
            figure {
              i {
                transition: all ease .3s;
                opacity: 1;
              }
            }
          }
        }
        .slick-dots {
          bottom: -60px;
          li {
            @include size(12px);
            button {
              @include size(12px);
              border: 1px solid #fff;
              background-color: #fff;
              opacity: .5;
            }
            &.slick-active {
              button {
                border-color: #fff;
                background-color: #fff;
                opacity: 1;
              }
            }
          }
        }
      }
      .prev {
        @include size(40px);
        position: absolute;
        background: url("../img/prev-arrow.png") no-repeat center center;
        left: -110px;
        top: 50%;
        margin-top: -50px;
      }
      .next {
        @include size(40px);
        position: absolute;
        background: url("../img/next-arrow.png") no-repeat center center;
        right: -110px;
        top: 50%;
        margin-top: -50px;
      }
    }
  }
  @media only screen and (max-width: 667px) {
    .main-slider.slick-slider {
      .slide {
        .image-holder {
          img {
            height: 188px;
            width: auto;
            min-width: auto;
          }
        }
      }
    }
    section.about {
      .title-wrapper {
        justify-content: center;
        position: relative;
        h2 {
          font-size: 18px;
          text-align: center;
          line-height: 18px;
          b {
            font-size: 18px;
          }
        }
        img {
          position: absolute;
          width: 47px;
          right: 21px;
          bottom: 10px;
        }
      }
      p {
        font-size: 14px;
        text-align: center;
      }
    }
    section.select-product {
      margin-bottom: 90px;
      .products-wrapper {
        margin: 0;
        height: 192px;
        h2 {
          font-size: 18px;
          top: -12px;
          b {
            display: block;
          }
        }
        .product-list {
          flex-direction: column;
          padding: 0;
          display: block;
          overflow-y: visible;
          .slick-track {
            padding-bottom: 20px;
          }
          .slick-track, .slick-list {
            overflow-y: visible;
          }
          .product {
            text-align: center;
            padding-bottom: 15px;
            img {
              height: 135px;
              width: auto;
              display: inline-block;
            }
            h4 {
              opacity: 1;
              transform: translateY(0);
              font-size: 10px;
              left: 0;
              right: 0;
              bottom: -20px;
            }
            &:after {
              opacity: 1;
              width: 86px;
              left: 50%;
              right: auto;
              margin-left: -43px;
              bottom: 0;
              height: 6px;
            }
          }
          .slick-dots {
            bottom: -30px;
            li {
              @include size(8px);
              button {
                @include size(8px);
                border: 1px solid #d0d0d0;
                background-color: #eaeaea;
              }
              &.slick-active {
                button {
                  border-color: #BF6011;
                  background-color: $orange;
                }
              }
            }
          }
        }
      }
    }
    section.videos {
      h2 {
        font-size: 18px;
        img {
          width: 47px;
          right: -60px;
        }
      }
      .video-slider-wrapper {
        padding-bottom: 0;
        .video-slider {
          .slick-track {
            padding: 40px 0 20px;
          }
          .video-box {
            opacity: .3;
            transition: all ease .3s;
            h4 {
              font-size: 12px;
            }
            &.slick-current + .slick-active {
              transform: scale(2.2);
              opacity: 1;
              position: relative;
              z-index: 9;
              transition: all ease .3s;
              figure {
                i {
                  font-size: 20px;
                  margin-top: -6px;
                }
              }
              h4 {
                font-size: 6px;
              }
            }
          }
          .slick-dots {
            bottom: -27px;
            li {
              @include size(8px);
              button {
                @include size(8px);
              }
            }
          }
        }
        .next, .prev {
          display: none;
        }
      }
    }
  }
}