.site-footer {
  background-color: #E2DEDE;
  padding: 30px 0 80px;
  .top-border {
    height: 32px;
    display: block;
    background-color: #CDC8C8;
  }
  .widgets-wrapper {
    .footer-widget {
      margin-top: -25px;
    }
    h4 {
      color: #8C8C8C;
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    .footer-menu {
      @include list-unstyled;
      margin: 0;
      li {
        width: 50%;
        float: left;
        margin-bottom: 10px;
        a {
          display: block;
          color: $gray;
          font-weight: 500;
          font-size: 12px;
        }
      }
    }
    .social-links {
      @include list-unstyled;
      margin: 30px 0 0 0;
      display: flex;
      align-items: center;
      li {
        margin-right: 10px;
        a {
          @include size(40px);
          border: 1px solid #e9c0ae;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: $orange;
          font-size: 20px;
          &:hover {
            border-color: $orange;
            color: $orange;
          }
        }
      }
    }
  }
  .call-center {
    display: block;
    margin-top: 26px;
    span {
      font-size: 10px;
      color: #8c8c8c;
      display: block;
      padding: 0 44px;
      margin-top: -7px;
      b {
        color: $orange;
      }
    }
  }
  @media only screen and (max-width: 667px) {
    padding: 15px 0;
    .top-border {
      display: none;
    }
    .widgets-wrapper {
      .footer-widget {
        margin-top: 0;
        h4 {
          margin: 0 -15px 15px;
          background-color: #CDC8C8;
          text-align: center;
          padding: 5px;
        }
        .footer-menu {
          margin-bottom: 20px;
          li {
            float: none;
            width: 100%;
            a {
              text-align: center;
              text-transform: uppercase;
            }
          }
        }
        .social-links {
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
    .call-center {
      margin: 20px auto 0 auto;
      width: 80%;
      display: table;
      span {
        font-size: 8px;
      }
    }
  }
}