.site-header {
  .language-selection {
    text-align: right;
    ul {
      @include list-unstyled;
      margin: 0;
      display: inline-flex;
      background-color: #f7f7f7;
      padding: 7px 10px;
      li {
        margin: 0 4px;
        a {
          font-size: 11px;
          @include size(20px);
          min-width: 20px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 1px solid transparent;
          color: $gray;
          &.active {
            color: $orange;
            border-color: $orange;
          }
        }
      }
    }
  }
  .menu-top {
    margin-top: -10px;
    .holder {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .logo-desktop {
        background-color: #fff;
        padding: 0 30px;
        position: relative;
        z-index: 2;
        bottom: -15px;
        img {
          width: 168px;
        }
      }
      .logo-mobile, .menu-bars {
        display: none;
      }
      .social-links {
        @include list-unstyled;
        margin: 30px 0 0 0;
        display: flex;
        align-items: center;
        li {
          margin-left: 10px;
          a {
            @include size(40px);
            border: 1px solid #d7d7d7;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: $darkgray;
            font-size: 20px;
            &:hover {
              border-color: $orange;
              color: $orange;
            }
          }
        }
      }
    }
  }
  .main-menu {
    border-top: 1px solid $orange;
    nav {
      text-align: right;
      ul {
        @include list-unstyled;
        margin: 0;
        display: inline-flex;
        align-items: center;
        li {
          margin-left: 37px;
          a {
            font-size: 14px;
            color: $gray;
            font-weight: 500;
            text-transform: uppercase;
            padding: 10px 0;
            display: block;
            &:hover, &.active {
              color: $orange;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 667px) {
    background-color: $orange;
    border-bottom: 1px solid #fff;
    .language-selection {
      ul {
        background-color: transparent;
        li {
          a {
            color: #fff;
            &.active {
              border-color: #fff;
              color: #fff;
            }
          }
        }
      }
    }
    .menu-top {
      .holder {
        align-items: center;
        justify-content: center;
        position: relative;
        .logo-desktop {
          display: none;
        }
        .menu-bars {
          display: block;
          position: absolute;
          left: 0;
          bottom: 15px;
        }
        .logo-mobile {
          display: block;
          position: relative;
          top: -15px;
          img {
            width: 90px;
            height: auto;
          }
        }
        .social-links {
          display: none;
        }
      }
    }
    .main-menu {
      display: none;
      background-color: #ddd9d9;
      nav {
        margin: 0 -12px;
        ul {
          flex-direction: column;
          width: 100%;
          padding: 10px 0;
          li {
            margin-left: 0;
            width: 100%;
            &:not(:last-child) {
              border-bottom: 1px solid rgba(0,0,0,.1);
            }
            a {
              display: block;
              text-align: center;
            }
          }
        }
      }
    }
  }
}