@font-face {
  font-family: 'Praktika';
  src: url('../fonts/praktikarnd-bold-webfont.eot');
  src: url('../fonts/praktikarnd-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/praktikarnd-bold-webfont.woff2') format('woff2'),
  url('../fonts/praktikarnd-bold-webfont.woff') format('woff'),
  url('../fonts/praktikarnd-bold-webfont.ttf') format('truetype'),
  url('../fonts/praktikarnd-bold-webfont.svg#praktika_rndbold') format('svg');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Praktika';
  src: url('../fonts/praktikarnd-light-webfont.eot');
  src: url('../fonts/praktikarnd-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/praktikarnd-light-webfont.woff2') format('woff2'),
  url('../fonts/praktikarnd-light-webfont.woff') format('woff'),
  url('../fonts/praktikarnd-light-webfont.ttf') format('truetype'),
  url('../fonts/praktikarnd-light-webfont.svg#praktika_rndlight') format('svg');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Praktika';
  src: url('../fonts/praktikarnd-medium-webfont.eot');
  src: url('../fonts/praktikarnd-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/praktikarnd-medium-webfont.woff2') format('woff2'),
  url('../fonts/praktikarnd-medium-webfont.woff') format('woff'),
  url('../fonts/praktikarnd-medium-webfont.ttf') format('truetype'),
  url('../fonts/praktikarnd-medium-webfont.svg#praktika_rndmedium') format('svg');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Praktika';
  src: url('../fonts/praktikarnd-regular-webfont.eot');
  src: url('../fonts/praktikarnd-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/praktikarnd-regular-webfont.woff2') format('woff2'),
  url('../fonts/praktikarnd-regular-webfont.woff') format('woff'),
  url('../fonts/praktikarnd-regular-webfont.ttf') format('truetype'),
  url('../fonts/praktikarnd-regular-webfont.svg#praktika_rndregular') format('svg');
  font-weight: normal;
  font-style: normal;
}