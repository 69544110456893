$font-family-base: 'Praktika';
$headings-font-family: 'Praktika';
$font-weight-base: normal;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 960px
) !default;

$darkgray: #5f5f5f;
$gray: #787472;
$orange: #fa672d;
$orange-light: #ec843d;
$blue: #0094c6;

$link-color: $orange;
$link-decoration: none;
$link-hover-decoration: none;


$body-color: $gray;
$body-bg: #FFF;

$grid-columns:                24 !default;
$grid-gutter-width:           24px !default;


$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1170px
) !default;


select.custom-select {
  background: url(../img/icons/arrow-down.svg) transparent no-repeat center right 10px;
  *background: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
}