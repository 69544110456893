section.hero {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.about-us {
    background: url("../img/page-hero-1.jpg") no-repeat center center;
  }
  &.sales-points {
    background: url("../img/page-hero-2.jpg") no-repeat center center;
  }
  &.news-room {
    background: url("../img/page-hero-3.jpg") no-repeat center center;
  }
  &.contact {
    background: url("../img/page-hero-4.jpg") no-repeat center center;
  }
  h1 {
    font-size: 65px;
    font-weight: bold;
    color: $orange;
    display: table;
    margin: -30px auto 0;
    text-transform: uppercase;
    position: relative;
    img {
      position: absolute;
      right: -110px;
      bottom: -28px;
    }
  }
  @media only screen and (max-width: 667px) {
    height: 110px;
    background-size: cover !important;
    align-items: flex-end;
    padding: 0 12px;
    h1 {
      font-size: 18px;
      background-color: rgba(226, 222, 222,.7);
      width: 100%;
      text-align: center;
      padding: 20px 0;
      img {
        right: 25px;
        width: 40px;
        bottom: 17px;
      }
    }
  }
}

.page-holder {
  padding: 60px 80px;
  margin: -150px 0 70px 0;
  background-color: #e5e1e1;
  h2 {
    text-align: center;
    color: $orange;
    font-size: 39px;
    font-weight: bold;
    position: relative;
    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  h3 {
    font-size: 34px;
    text-align: center;
    color: $orange;
  }
  h4 {
    font-size: 27px;
    color: $orange;
  }
  @media only screen and (max-width: 667px) {
    padding: 30px;
    margin: 0 0 50px 0;
    h2 {
      font-size: 16px;
      img {
        width: 40px;
        right: 10px;
      }
    }
    h3 {
      font-size: 16px;
    }
    h4 {
      font-size: 14px;
    }
    p {
      font-size: 13px;
    }
  }
}

.product-box {
  display: inline-block;
  width: 100%;
  margin-bottom: 50px;
  img {
    float: left;
    margin-right: 30px;
  }
  h4 {
    font-size: 22px;
    color: $blue;
    b {
      color: $orange;
    }
  }
  p {
    font-size: 12px;
  }
  @media only screen and (max-width: 667px) {
    margin-bottom: 20px;
    img {
      width: 100px;
      margin-right: 15px;
    }
    h4 {
      font-size: 15px;
      b {
        display: block;
      }
    }
    p {
      font-size: 10px;
    }
  }
}

.filterbox {
  background-color: #fff;
  padding: 5px 15px;
  margin-bottom: 30px;
  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
    }
    .custom-select {
      width: 130px;
      border-radius: 30px;
      background: url("../img/select-arrow.png") no-repeat right 10px center;
    }
  }
  @media only screen and (max-width: 667px) {
    form {
      flex-direction: column-reverse;
      p {
        text-align: center;
        font-size: 11px;
        margin-top: 5px;
      }
    }
  }
}

.sales-point-box {
  margin-bottom: 50px;
  .name {
    background-color: #edeaea;
    padding: 8px 10px;
    font-weight: 500;
  }
  .address {
    padding: 10px;
    b {
      color: $orange;
      margin-right: 5px;
    }
  }
  @media only screen and (max-width: 667px) {
    margin-bottom: 30px;
    .name {
      font-size: 14px;
    }
    .address {
      font-size: 13px;
    }
  }
}

.news-box {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  img {
    height: 195px;
    min-height: 195px;
    max-height: 195px;
  }
  .text-holder {
    height: 195px;
    background-color: #fff;
    padding: 30px;
    position: relative;
    .date {
      background-color: $orange;
      color: #fff;
      padding: 3px 12px;
      position: absolute;
      right: 30px;
      top: -14px;
    }
    h4 {
      font-size: 24px;
      color: $orange;
      margin-bottom: 30px;
    }
  }
  &.layout-1 {
    img {
      margin-right: 20px;
    }
  }
  &.layout-2 {
    flex-direction: row-reverse;
    img {
      margin-left: 20px;
    }
  }
  @media only screen and (max-width: 667px) {
    margin: 0 -10px 30px -10px;
    img {
      height: 85px;
      min-height: 85px;
      max-height: 85px;
    }
    .text-holder {
      height: 85px;
      padding: 10px;
      font-size: 9px;
      white-space: normal;
      h4 {
        font-size: 10px;
        margin-bottom: 10px;
      }
      .date {
        right: 10px;
      }
    }
    &.layout-1 {
      img {
        margin-right: 10px;
      }
    }
    &.layout-2 {
      flex-direction: row-reverse;
      img {
        margin-left: 10px;
      }
    }
  }
}

.address-box {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  b {
    margin-right: 10px;
    color: $orange;
  }
  @media only screen and (max-width: 667px) {
    font-size: 13px;
  }
}

table {
  td {
    padding: 5px 0;
  }
  @media only screen and (max-width: 667px) {
    font-size: 13px;
    td:first-child {
      width: 115px;
    }
    td:last-child {
      display: flex;
    }
  }
}